<script setup>

import { computed } from 'vue'

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const props = defineProps({
  modelValue: {
    required: true,
    type: Object
  }
})

const emit = defineEmits(['update:modelValue'])

const model = computed(() => {
  return new Proxy(props.modelValue, {
    set (obj, key, value) {
      emit('update:modelValue', { ...obj, [key]: value })
      return true
    }
  })
})

const rules = {
  firstName: { required }, // Matches state.firstName
  secondName: {},
  lastName: { required }, // Matches state.firstName
  phone: { required }, // Matches state.contact.email
  email: { email } // Matches state.contact.email
}

const v$ = useVuelidate(rules, model)

</script>

<template>
  <div class="grid grid-cols-6 gap-6">
    <div class="col-span-6">
      <label for="lastName" class="block text-sm font-medium text-gray-700">Фамилия</label>
      <input v-model="model.lastName" type="text" name="lastName" id="lastName" autocomplete="lastName"
        :class="{ 'input-error': v$.lastName.$silentErrors.length != 0 }" />
      <div class="text-sm text-red-500">
        <ul v-if="v$.lastName.$silentErrors.length !=0">
          <li v-for="err in v$.lastName.$silentErrors" :key="err.$uuid">{{ err.$message }}</li>
        </ul>
      </div>
    </div>
    <div class="col-span-6 ">
      <label for="firstName" :class="{ 'input-error': v$.firstName.$silentErrors.length != 0 }">Имя</label>
      <input v-model="model.firstName" type="text" name="firstName" id="firstName" autocomplete="firstName"
        :class="{ 'input-error': v$.firstName.$silentErrors.length != 0 }" />
      <div class="text-sm text-red-500">
        <ul v-if="v$.firstName.$silentErrors.length !=0">
          <li v-for="err in v$.firstName.$silentErrors" :key="err.$uuid">{{ err.$message }}</li>
        </ul>
      </div>
    </div>
    <div class="col-span-6">
      <label for="secondName" class="block text-sm font-medium text-gray-700">Отчество</label>
      <input v-model="model.secondName" type="text" name="secondName" id="secondName" autocomplete="secondName"
        :class="{ 'input-error': v$.secondName.$silentErrors.length != 0 }" />
      <div class="text-sm text-red-500">
        <ul v-if="v$.secondName.$silentErrors.length !=0">
          <li v-for="err in v$.secondName.$silentErrors" :key="err.$uuid">{{ err.$message }}</li>
        </ul>
      </div>
    </div>

    <div class="col-span-6">
      <label for="phone" :class="{ 'input-error': v$.phone.$silentErrors.length != 0 }">Телефон</label>
      <input v-model="model.phone" type="text" name="phone" id="phone" autocomplete="given-name"
        :class="{ 'input-error': v$.phone.$silentErrors.length != 0, 'focus:border-indigo-500 focus:ring-indigo-500': v$.phone.$silentErrors.length == 0 }" />
      <div class="text-sm text-red-500">
        <ul v-if="v$.phone.$silentErrors.length !=0">
          <li v-for="err in v$.phone.$silentErrors" :key="err.$uuid">{{ err.$message }}</li>
        </ul>
      </div>
    </div>

    <div class="col-span-6">
      <label for="email-address" :class="{ 'input-error': v$.email.$silentErrors.length != 0 }">Email address</label>
      <input v-model="model.email" type="text" name="email-address" id="email-address" autocomplete="email"
        :class="{ 'input-error': v$.email.$silentErrors.length != 0 }" />
      <div class="text-sm text-red-500">
        <ul v-if="v$.email.$silentErrors.length !=0">
          <li v-for="err in v$.email.$silentErrors" :key="err.$uuid">{{ err.$message }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>